import BasePlugin from '../BasePlugin'
import ApprovalInvestmentApplicationsCmp from './ApprovalInvestmentApplicationsCmp'

export default class ApprovalInvestmentApplications extends BasePlugin {
  async execute () {
    const h = this.context.$createElement
    let values = this.context.getModel()
    let statuses = [22, 23, 24, 25, 26, 27, 17, 28 ]
    let message = 'Инвестиционная заявка уже отправлена на согласование!'
    if (this.context.getModel().attr_689_ === 18) {
      this.context.$msgbox({
        type: 'info',
        message: message
      })
      done()
    } else if (statuses.includes(this.context.getModel().attr_689_)) {
      let message = 'Инвестиционная заявка уже согласована!'
      this.context.$msgbox({
        type: 'info',
        message: message
      })
      done()
    } else {
      this.context.$msgbox({
        title: 'Согласование инвестиционных заявок',
        message: h('p', { key: Math.random() }, [
          h(ApprovalInvestmentApplicationsCmp, { props: { model: values } })
        ]),
        showCancelButton: true,
        confirmButtonText: 'Да',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let payload = {
              'investmentApplicationId': this.context.getModel().id,
              'categoryProjectId': this.context.getModel().attr_134_,
              'featureProjectId': this.context.getModel().attr_1046_
            }
            this.send(payload).then((response) => {
              if (response.data === 'Required documents are not filled') {
                let message = 'Заполните приложения в обязательных документах!'
                this.context.$msgbox({
                  type: 'info',
                  message: message
                })
              } else if (response.data === 'Missing required documents' ||
                response.data === 'All ok') {
                let message = 'Заявка отправлена на согласование!'
                this.context.$msgbox({
                  type: 'info',
                  message: message
                })
                this.context.getCard().$emit('cancelChanges')
              } else if (response.data === 'Missing not affixed required documents') {
                let message = 'Найдены обязательные документы! Пожалуйста, заполните их.'
                this.context.$msgbox({
                  type: 'info',
                  message: message
                })
              } else {
                console.log(response.data)
                this.context.getModel().attr_89_ = response.data.serial_number
              }
            })
          }
          done()
        }
      })
    }
  }
}
