<template>
  <div align="center">
    <strong>Вы действительно хотите отправить на согласование данную инвестиционную заявку ?</strong>
  </div>
</template>
<script>
    export default {
        props: ['model'],
        name: 'ApprovalInvestmentApplicationsCmp',
        data () {
            return {
                local: this.model
            }
        },
        watch: {
            local () {
                this.model = this.local
            },
            model () {
                this.local = this.model
            }
        }
    }
</script>

<style scoped>

</style>
